import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import spyGlassIcon from "../../images/spy-glass-icon-landon-johnson.svg"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"
import findAnHerbLogoWhite from "../../images/find-an-herb-logo-white.svg"
import findAnHerbLogoDark from "../../images/find-an-herb-logo-dark.svg"
import stepLineTop from '../../images/step-line-top.svg'
import stepLineBottom from '../../images/step-line-bottom.svg'
import defaultHerbListIcon from "../../images/defaultHerbListIcon.svg"
import defaultProductListIcon from "../../images/defaultProductListIcon.svg"
import greenSeedAvatarIcon from "../../images/avatars/green-seed-find-an-herb-avatar.jpg"
import greenFlowerAvatarIcon from "../../images/avatars/green-flower-find-an-herb-avatar.jpg"
import blackTulipAvatarIcon from "../../images/avatars/black-tulip-flower-find-an-herb-avatar.jpg"
import greenSproutAvatarIcon from "../../images/avatars/green-sprout-find-an-herb-avatar.jpg"
import greenTreeAvatarIcon from "../../images/avatars/green-tree-find-an-herb-avatar.jpg"
import blackSproutAvatarIcon from "../../images/avatars/black-sprout-find-an-herb-avatar.jpg"
import agent99SprayBottleBackdrop from "../../images/advertising/agent-99-cleaning-sanitizer-solution.png"
import agent99Logo from "../../images/advertising/agent99-white-logo.svg"
import findanherbHeroImage from '../../images/findanherb-app-hero.gif';
import iosDownloadBadge from '../../images/download-on-app-store-badge.svg'
import googlePlayDownloadBadge from '../../images/download-on-play-store-badge.svg'
import herbAppImageTop75Percent from '../../images/herbapp-image-top-75-percent.png'
import arrowLeft from '../../images/arrow-left-landon-johnson-dev-icon.svg'
import arrowRight from '../../images/arrow-right-landon-johnson-dev-icon.svg'

import heroBackdrop from '../../images/herobackdrop.jpg'


// Media Query Breakpoints
export const BreakpointSmallMobile = 420
export const BreakpointMobile = 575
export const BreakpointTablet = 768
export const BreakpointLargeDevices = 922
export const BreakpointDesktop = 1200

// Padding Page Variables
export const PaddingLeftRightDesktop = "3%"
export const PaddingLeftRightMobile = "2%"
export const MaxWidthPageDesktopOne = 1200


const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "870px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}
export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.143"
      height={20}
      viewBox="0 0 17.143 20"
    >
      <path
        id="Path_14"
        data-name="Path 14"
        d="M259.572,243.857h-4.286v-1.071A1.786,1.786,0,0,0,253.5,241h-3.572a1.786,1.786,0,0,0-1.785,1.786v1.071h-4.286a.714.714,0,0,0,0,1.429h.759l.848,13.612a2.153,2.153,0,0,0,2.143,2.1h8.215a2.144,2.144,0,0,0,2.143-2.1l.848-13.616h.759a.714.714,0,0,0,0-1.429Zm-10.689,14.285h-.025a.713.713,0,0,1-.714-.689l-.357-10a.715.715,0,1,1,1.429-.051l.357,10a.714.714,0,0,1-.688.739Zm3.546-.714a.714.714,0,0,1-1.428,0v-10a.714.714,0,1,1,1.428,0Zm1.429-13.571h-4.285v-1.071a.353.353,0,0,1,.349-.357h3.58a.353.353,0,0,1,.357.349v1.08Zm1.429,13.6a.713.713,0,0,1-.714.689h-.026a.714.714,0,0,1-.689-.739h0l.357-10a.715.715,0,1,1,1.429.051Z"
        transform="translate(-243.143 -241)"
        fill="#d1d1d1"
      />
    </svg>
  )
}

export const ArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99.033"
      height="176.853"
      viewBox="0 0 99.033 176.853"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".a{fill:none;stroke:#eaeaea;stroke-linecap:round;stroke-linejoin:round;stroke-width:15px;}",
          }}
        />
      </defs>
      <path
        className="a"
        d="M-8959.5-1407.9l-83.123,83.123,83.123,83.123"
        transform="translate(9053.229 1413.199)"
      />
    </svg>
  )
}

export const Logo = () => {
  return (
    <svg
      style={{ width: 200, height: 60 }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200.2 60"
      style={{ enableBackground: "new 0 0 200.2 60" }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            className="st0"
            d="M46.3,25.3c-1.6,5.4-5.8,9.6-11.4,11c-3.9,1-8,0.5-11.5-1.4v0c-0.1-0.1-0.3-0.1-0.4-0.2
			c0.1,0.1,0.3,0.1,0.4,0.2l-1.9,2.8c0.2,0.5,0.3,1,0.3,1.5c0,0.6-0.2,1.3-0.6,1.9l-0.8,1.2c-0.2,0.3-0.4,0.5-0.6,0.7
			c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.7-0.4c0.2,0.2,0.4,0.4,0.6,0.5
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.5,0.3,0.7,0.4c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.3-0.2,0.6-0.4,0.9l-1.4,2
			c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.7-0.4c0.2,0.2,0.4,0.4,0.6,0.5
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.1,0.4,0.3,0.6,0.4c-0.1,0.3-0.2,0.6-0.4,0.9l-1.4,2c-0.2,0.3-0.5,0.6-0.8,0.8
			c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.7,0.4
			c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.4-0.2,0.7-0.5,1.1l-0.8,1.2c-1,1.5-3.1,1.9-4.7,0.9c-1-0.6-1.5-1.7-1.5-2.8c0-0.2,0-0.3,0-0.4
			c0,0,0-1.3,1.7-3.1c0,0,0,0,0,0c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0.4-2,2.3-3.7c0,0,0,0,0,0
			c0.1-0.1,0.3-0.3,0.5-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.3,0.2-0.5c0,0,0,0,0,0c0.3-0.9,1.1-2.6,2.6-3.5c0,0,0,0,0,0
			c0-0.1,0.3-1.7,1.8-3c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0,0s0,0,0,0c0.6-0.3,1.2-0.5,1.9-0.4l1.9-2.8c-2.5-2.1-4.4-5-5.2-8.2
			c-0.3-1.3-0.5-2.6-0.5-3.9c0-0.7,0-1.4,0.1-2.1c0.1-0.6,0.2-1.2,0.3-1.8c1.7,3.5,1.1,4.6,2.9,7c0.2,0.2,0.4,0.5,0.6,0.7
			c1,2.9,2.9,5.4,5.6,7c0.5,0.3,1,0.6,1.6,0.8c0,0,0,0,0,0c1.4,0.6,2.9,0.9,4.5,1c1.2,0,2.4-0.1,3.6-0.4c1.5-0.4,2.9-1,4.1-1.9
			c1.1-0.8,2-1.7,2.8-2.7c0.2-0.3,0.5-0.7,0.7-1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
			c0,0,0.1,0,0.1,0c0.4-0.1,0.8-0.2,1.2-0.3c0,0,0,0,0,0C44.5,26.7,45.4,26.2,46.3,25.3z"
          />
          <path
            className="st0"
            d="M23.4,34.9L23.4,34.9L23.4,34.9c-0.1-0.1-0.3-0.1-0.4-0.2C23.1,34.7,23.3,34.8,23.4,34.9z"
          />
          <path
            className="st0"
            d="M8.9,47.8c-0.2,0.2-0.4,0.3-0.6,0.5C8.5,48.1,8.7,47.9,8.9,47.8z"
          />
          <path
            className="st0"
            d="M11.7,43.7c-0.2,0.1-0.3,0.3-0.5,0.4C11.3,44,11.5,43.8,11.7,43.7z"
          />
          <path
            className="st0"
            d="M11.8,43.1c-0.1,0.2-0.1,0.4-0.2,0.5C11.7,43.5,11.8,43.3,11.8,43.1z"
          />
          <path
            className="st0"
            d="M16.8,36.2c-0.2,0.1-0.4,0.3-0.6,0.5C16.4,36.5,16.6,36.3,16.8,36.2z"
          />
          <path
            className="st0"
            d="M23.4,34.9L23.4,34.9L23.4,34.9c-0.1-0.1-0.3-0.1-0.4-0.2C23.1,34.7,23.3,34.8,23.4,34.9z"
          />
          <path
            className="st0"
            d="M16.9,36.2L16.9,36.2C16.9,36.2,16.9,36.2,16.9,36.2C16.9,36.2,16.9,36.2,16.9,36.2z"
          />
          <path
            className="st0"
            d="M42.9,24.4L42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4z M42.9,24.4
			C42.9,24.4,42.9,24.4,42.9,24.4L42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4z"
          />
          <path
            className="st0"
            d="M42.9,24.4L42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4z"
          />
          <path
            className="st0"
            d="M42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4L42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4z"
          />
          <path
            className="st0"
            d="M42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4L42.9,24.4z"
          />
          <path
            className="st0"
            d="M42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4L42.9,24.4C42.9,24.4,42.9,24.4,42.9,24.4z"
          />
          <path
            className="st0"
            d="M46.2,23.1C46.2,23.1,46.2,23.1,46.2,23.1C46.2,23.1,46.2,23.1,46.2,23.1C46.2,23.1,46.2,23.1,46.2,23.1z"
          />
          <path
            className="st0"
            d="M46.2,23.1C46.2,23.1,46.2,23.1,46.2,23.1C46.2,23.2,46.2,23.2,46.2,23.1C46.2,23.2,46.2,23.2,46.2,23.1z"
          />
          <path
            className="st0"
            d="M45.3,24.6c-2.7,2.8-7.9,2.4-11.7-0.9c-3.9-3.3-4.4-8.4-9.2-11.5c-4.7-3.1-9.2-1.7-9.2-1.7
			c8.6-1.4,12.4,5.7,13.5,7.9c2.4,5,4.1,6.2,4.1,6.2c-8,2.8-11.5,0.8-13.5-1.8c-2-2.7-1.1-3.8-3.4-8c-2.3-4.2-5.2-5-5.2-5
			c2.8-1.2,6.1-2.2,9.5-2.3c8.3-0.3,10.6,6.5,11.2,9.7c0.7,3.1,3.5,5.8,7.6,7.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0.8,0.2,1.4,0.2,2,0.1c1.8-0.2,3.8-1.3,3.2-5.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c-0.3-1.9-1.3-4.8-4.8-7.5
			c-6-4.8-11.9-2.5-11.9-2.5c-2.4-1.7-4.3-1.6-4.3-1.6c12.5-6.5,20.3,4,20.3,4C46.9,14.7,48,21.8,45.3,24.6z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M69.5,31.6h-7.7v9.2H59V20.2h11.8v2.2h-8.9v7.1h7.7V31.6z"
          />
          <path
            className="st0"
            d="M76.1,21.4h-2.9v-2.7h2.9V21.4z M76.1,40.8h-2.9V25.5h2.9V40.8z"
          />
          <path
            className="st0"
            d="M82.2,25.5l0.2,2.3c0.4-0.8,1-1.4,1.6-1.9c0.7-0.4,1.4-0.7,2.2-0.7c1.3,0,2.4,0.5,3.1,1.5
			c0.7,1,1.1,2.5,1.1,4.6v9.5h-2.9v-9.5c0-1.4-0.2-2.4-0.6-3c-0.4-0.6-1-0.9-1.8-0.9c-0.6,0-1.1,0.2-1.6,0.5s-0.8,0.9-1.1,1.5v11.3
			h-2.9V25.5H82.2z"
          />
          <path
            className="st0"
            d="M93.2,33.3c0-2.4,0.4-4.4,1.3-5.9c0.9-1.5,2.1-2.2,3.7-2.2c0.7,0,1.4,0.2,1.9,0.5s1,0.8,1.5,1.4v-8.4h2.9
			v22.1h-2.5l-0.2-1.8c-0.4,0.7-0.9,1.2-1.5,1.5s-1.3,0.5-2,0.5c-1.6,0-2.8-0.7-3.7-2s-1.3-3.2-1.3-5.4V33.3z M96,33.6
			c0,1.6,0.2,2.9,0.7,3.8s1.2,1.4,2.2,1.4c0.6,0,1.2-0.2,1.6-0.5c0.4-0.3,0.8-0.8,1.1-1.4v-7.4c-0.3-0.6-0.6-1.1-1.1-1.5
			c-0.4-0.4-1-0.6-1.6-0.6c-1,0-1.7,0.5-2.2,1.6c-0.5,1.1-0.7,2.5-0.7,4.3V33.6z"
          />
          <path
            className="st0"
            d="M117.5,35.6h-6.6l-1.5,5.2h-2.9l6.3-20.6h2.8l6.3,20.6H119L117.5,35.6z M111.6,33.3h5.3l-2.6-9h-0.1
			L111.6,33.3z"
          />
          <path
            className="st0"
            d="M126.5,25.5l0.2,2.3c0.4-0.8,1-1.4,1.6-1.9c0.7-0.4,1.4-0.7,2.2-0.7c1.3,0,2.4,0.5,3.1,1.5
			c0.7,1,1.1,2.5,1.1,4.6v9.5h-2.9v-9.5c0-1.4-0.2-2.4-0.6-3c-0.4-0.6-1-0.9-1.8-0.9c-0.6,0-1.1,0.2-1.6,0.5
			c-0.5,0.4-0.8,0.9-1.1,1.5v11.3h-2.9V25.5H126.5z"
          />
          <path
            className="st0"
            d="M152,40.8h-2.9v-9.1h-7.9v9.1h-2.9V20.2h2.9v9.3h7.9v-9.3h2.9V40.8z"
          />
          <path
            className="st0"
            d="M161.1,41.1c-1.8,0-3.3-0.6-4.4-1.9c-1.1-1.3-1.6-2.9-1.6-4.9v-2.2c0-2,0.5-3.7,1.6-5s2.4-2,4.1-2
			c1.7,0,3.1,0.6,4,1.8c0.9,1.2,1.4,2.8,1.4,4.8v2H158v0.6c0,1.3,0.3,2.4,0.9,3.3c0.6,0.9,1.4,1.3,2.5,1.3c0.8,0,1.5-0.1,2-0.4
			s1.1-0.6,1.6-1.1l0.9,1.8c-0.5,0.5-1.1,1-1.9,1.3C163.1,40.9,162.2,41.1,161.1,41.1z M160.8,27.4c-0.8,0-1.5,0.4-2,1.2
			c-0.5,0.8-0.8,1.8-0.8,3.1h5.4v-0.6c0-1.1-0.2-2-0.7-2.7C162.3,27.7,161.7,27.4,160.8,27.4z"
          />
          <path
            className="st0"
            d="M175.4,27.9l-1.3-0.1c-0.6,0-1.1,0.2-1.5,0.5s-0.7,0.9-0.9,1.5v10.9h-2.9V25.5h2.6l0.2,2.3
			c0.3-0.8,0.7-1.4,1.3-1.9c0.5-0.5,1.1-0.7,1.8-0.7c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.5,0.1L175.4,27.9z"
          />
          <path
            className="st0"
            d="M189.1,34.4c0,2-0.4,3.7-1.3,4.9s-2.1,1.8-3.7,1.8c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.4-1.1-0.9-1.5-1.6l-0.2,1.8
			h-2.5V18.7h2.9v8.4c0.4-0.6,0.9-1.1,1.4-1.4c0.6-0.3,1.2-0.5,2-0.5c1.6,0,2.9,0.6,3.7,1.9s1.3,3,1.3,5.2V34.4z M186.2,32.3
			c0-1.5-0.2-2.7-0.7-3.6c-0.5-0.9-1.2-1.3-2.2-1.3c-0.6,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.8,0.9-1.1,1.5v7.4c0.3,0.6,0.6,1.1,1.1,1.5
			s1,0.5,1.7,0.5c1,0,1.7-0.4,2.2-1.2c0.5-0.8,0.7-1.9,0.7-3.3V32.3z"
          />
        </g>
      </g>
    </svg>
  )
}

export const ChevronForwardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.714"
      height={10}
      viewBox="0 0 5.714 10"
    >
      <g
        id="Group_1182"
        data-name="Group 1182"
        transform="translate(255.714 255.5) rotate(180)"
      >
        <path
          id="Path_62"
          data-name="Path 62"
          d="M255,255.5a.712.712,0,0,1-.505-.209l-4.286-4.286a.715.715,0,0,1,0-1.01l4.286-4.286a.715.715,0,0,1,1.01,1.01l-3.781,3.781,3.781,3.781A.714.714,0,0,1,255,255.5Z"
          transform="translate(0)"
          fill="rgba(77,77,77,0.5)"
        />
      </g>
    </svg>
  )
}

export const SpyGlassIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.032"
      height="19.032"
      viewBox="0 0 19.032 19.032"
    >
      <g id="Group_11" data-name="Group 11" transform="translate(-1031 -15)">
        <g
          id="Ellipse_1"
          data-name="Ellipse 1"
          transform="translate(1031 15)"
          fill="none"
          stroke="white"
          strokeWidth="2"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7" fill="none" />
        </g>
        <line
          id="Line_4"
          data-name="Line 4"
          x2="5.553"
          y2="5.553"
          transform="translate(1043.772 27.772)"
          fill="none"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export const CallToActionButton = styled.button`
  height: 30px;
  border: 0px solid transparent;
  color: var(--LinkColor);
  text-decoration: underline;
  font-size: large;
  cursor: pointer;
  padding: 0px;
  background: transparent;
`

export const BulletPointIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
    >
      <rect
        id="Rectangle_27"
        data-name="Rectangle 27"
        width="6"
        height="6"
        fill="#05aa97"
      />
    </svg>
  )
}

export let socialMediaUrls = {
  twitter: "https://bit.ly/findanherb-on-twitter",
  facebook: "https://bit.ly/findanherb-on-facebook",
  instagram: "https://bit.ly/findanherb-on-instagram",
  linkedin: "https://bit.ly/FindAnHerb-on-linkedin",
  tiktok: "http://bit.ly/findanherb-tiktok",
  youtube: "http://bit.ly/findanherb-youtube"
}

export const appDownloadURLS = {
  ios: "https://apple.co/2KHUuDv",
  android: "http://bit.ly/findanherb-android"
}

export const FacebookLink = () => {
  return (
    <OutboundLink target={"_blank"} href={socialMediaUrls.facebook}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 23.3 23.3"
        style={{ enableBackground: "new 0 0 23.3 23.3" }}
      >
        <path
          className="st0"
          d="M23,0H1.7c-0.6,0-1,0.4-1,1v21.3c0,0.6,0.4,1,1,1h11.4v-8.8h-2.9v-3.6h2.9V8c0-3,1.9-4.5,4.6-4.5h2.7v3.3h-1.9
        c-1.5,0-1.8,0.7-1.8,1.7v2.4h3.6l-0.7,3.6h-2.9v8.7H23c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0"
        />
      </svg>
    </OutboundLink>
  )
}

export const TwitterLink = () => {
  return (
    <OutboundLink target={"_blank"} href={socialMediaUrls.twitter}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.27 18.91"
      >
        <path d="M23.27,2.24A9.58,9.58,0,0,1,20.53,3,4.8,4.8,0,0,0,22.63.35a9.58,9.58,0,0,1-3,1.16,4.78,4.78,0,0,0-8.26,3.26,4.86,4.86,0,0,0,.12,1.09,13.56,13.56,0,0,1-9.84-5A4.77,4.77,0,0,0,3.1,7.25a4.74,4.74,0,0,1-2.16-.6.13.13,0,0,0,0,.06,4.78,4.78,0,0,0,3.84,4.68,4.85,4.85,0,0,1-1.26.17,4.43,4.43,0,0,1-.9-.09,4.78,4.78,0,0,0,4.46,3.32,9.59,9.59,0,0,1-5.93,2A10.6,10.6,0,0,1,0,16.77a13.54,13.54,0,0,0,7.32,2.14A13.49,13.49,0,0,0,20.9,5.33c0-.21,0-.42,0-.62a9.64,9.64,0,0,0,2.38-2.47"></path>
      </svg>
    </OutboundLink>
  )
}

export const TwitterIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="twitter"
      className="svg-inline--fa fa-twitter fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
      />
    </svg>
  )
}

export const FacebookIcon = () => {
  return (
    <svg
      ariahidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="facebook-f"
      className="svg-inline--fa fa-facebook-f fa-w-10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      />
    </svg>
  )
}

export const RedditIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="reddit-alien"
      class="svg-inline--fa fa-reddit-alien fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z"
      ></path>
    </svg>
  )
}

export const LinkedInIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="linkedin-in"
      class="svg-inline--fa fa-linkedin-in fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
      ></path>
    </svg>
  )
}

export const PinterestIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="pinterest-p"
      class="svg-inline--fa fa-pinterest-p fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"
      ></path>
    </svg>
  )
}

export const InstagramLink = () => {
  return (
    <OutboundLink target={"_blank"} href={socialMediaUrls.instagram}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 23.3 23.3"
        style={{ enableBackground: "new 0 0 23.3 23.3" }}
      >
        <path
          className="st0"
          d="M20.4,21.1H2.9c-0.4,0-0.7-0.3-0.7-0.7V9.4h2.9c-0.4,0.7-0.5,1.4-0.5,2.2c0,3.9,3.2,7.1,7.1,7.1
      c3.9,0,7.1-3.2,7.1-7.1c0-0.8-0.1-1.5-0.6-2.2h2.9v10.9C21.1,20.8,20.8,21.1,20.4,21.1C20.4,21.1,20.4,21.1,20.4,21.1 M11.7,7.2
      c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5c0,0,0,0,0,0C7.2,9.2,9.2,7.2,11.7,7.2 M17.5,2.9h2.2
      c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0v2.2c0,0.4-0.3,0.7-0.7,0.7h-2.2c-0.4,0-0.7-0.3-0.7-0.7V3.6C16.7,3.2,17,2.9,17.5,2.9
      C17.4,2.9,17.4,2.9,17.5,2.9 M20.5,0H2.8C1.2,0,0,1.2,0,2.8v17.8c0,1.5,1.2,2.8,2.8,2.8c0,0,0,0,0,0h17.7c1.5,0,2.8-1.2,2.8-2.8l0,0
      V2.8C23.3,1.2,22,0,20.5,0"
        />
      </svg>
    </OutboundLink>
  )
}

export const LegalInfoContainer = styled.div`
  background: white;

  padding-bottom: ${PaddingLeftRightDesktop};
  padding-top: var(--ContainerPaddingTopForHeader);

  .agreement-border {
    border: 1px solid #ccc;
  }

  .agreement-column {
    padding-top: 20px;
    padding-bottom: 3%;
    border-radius: 4px;
  }

  .agreement-columns-grid {
    display: grid;
    grid-template-columns: 800px 1fr;
  }

  .not-found-text {
    opacity: 0.5 !important;
    text-decoration: line-through !important;
  }

  .small-br {
    display: block;
  }
  h2 {
    font-size: x-large;
    line-height: 20px;
    width: 100%;
    font-family: var(--Font1);
    margin-bottom: 15px;
    text-transform: uppercase;

  }
  .move-list-left {
    padding-left: 20px;
  }
  label {
    margin-top: 2px;
    font-family: var(--Font2);
    font-size: medium;
  }
  p {
    margin-top: 2px;
    font-family: var(--Font2);
    font-size: medium;
    margin-bottom: 15px;
    a {
      color: inherit;
    }
  }

  h1 {
    margin-top: 50px;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height: 35px;
    font-family: var(--Font1);
    font-size: xx-large;
    margin-bottom: 15px;

    :first-of-type{
      margin-top: 20px;
    }
  }
  ul,
  ol {
    margin-top: 0px;
    font-size: medium;
    margin-top: 0px;
    padding-bottom: 5px;
    padding-left: 15px;
  }
  .noMarginBottom {
    margin-bottom: 5px;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    .agreement-columns-grid {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`

export const SectionLabelOne = styled.h2`
  /* font-family: var(--Font1);
      text-transform: uppercase;
      font-weight: bold;
      color: #222220;
      lobne-height: 25px; */
  ${props =>
    props.paddingBottom
      ? `padding-bottom: ${props.paddingBottom}px;`
      : "padding-bottom: 15px;"}
  /* margin-top: 0px;
      margin-bottom: 0px;
      font-size: 24px;
      user-select: none; */



      font-family: var(--Font1);
  font-weight: bold;

  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0px;

  .not-found-text {
    text-decoration: line-through;
    opacity: 0.3;
  }
`

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={20}
      height={20}
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="clip-plus-icon">
          <rect width={20} height={20} />
        </clipPath>
      </defs>
      <g id="plus-icon" clipPath="url(#clip-plus-icon)">
        <rect width={20} height={20} fill="#fff" />
        <g id="Group_1" data-name="Group 1">
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width={4}
            height={20}
            transform="translate(12 20) rotate(180)"
            fill="#020202"
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width={4}
            height={20}
            transform="translate(0 12) rotate(-90)"
            fill="#020202"
          />
        </g>
      </g>
    </svg>
  )
}

export const MinusIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20">
      <defs>
        <clipPath id="clip-minus-icon">
          <rect width={20} height={20} />
        </clipPath>
      </defs>
      <g id="minus-icon" clipPath="url(#clip-minus-icon)">
        <rect width={20} height={20} fill="#fff" />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width={4}
          height={20}
          transform="translate(0 12) rotate(-90)"
          fill="#020202"
        />
      </g>
    </svg>
  )
}


const lightTheme = {
  primaryTextColor: "rgba(5, 5, 5, 0.87)",
  textLinkColorOne: "#1861BF"
}

export const SectionLabelTwo = styled.h3`
  font-family: var(--Font1);
  font-weight: bold;
  padding-bottom: 12px;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  ${props =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : ""};
`

export const theme = {
  spyGlassIcon,
  defaultHerbListIcon,
  defaultProductListIcon,
  icons: {
    arrowLeft,
    arrowRight,
  },
  logos: {
    agent99Logo,
    findAnHerbLogoWhite,
    findAnHerbLogoDark
  },
  images: {
    findanherbHeroImage,
    herbAppImageTop75Percent,
    stepLineTop,
    stepLineBottom,
    heroBackdrop
  },
  badges:{
    googlePlayDownloadBadge,
    iosDownloadBadge
  },
  advertising: {
    agent99SprayBottleBackdrop,
  },
  avatars: [
    greenSeedAvatarIcon,
    greenFlowerAvatarIcon,
    blackTulipAvatarIcon,
    greenSproutAvatarIcon,
    greenTreeAvatarIcon,
    blackSproutAvatarIcon,
  ],
  ...lightTheme
}



export const GlobalStyle = createGlobalStyle`

.gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
@keyframes placeHolderShimmer{
    0%{
        background-position: -100vw 0
    }
    100%{
        background-position: 100vw 0
    }
}

.link-style-one{
  color: #1b88bf;
  font-size: 13px;
  text-decoration: underline;
  font-family: var(--Font1NotCondensed);
}

:root {

  --Font1: 'Roboto Condensed', "sans-serif";
  --Font1NotCondensed: 'Roboto', "sans-serif";
  --ErrorColor: #e53935;
  --Font2: 'Cabin', sans-serif;
  /* --Color1: #69A839;
  --Color2: #F0DD1D; */
  --Color1: #05AA97;
  --Color1Lightest: #A7DDD4;
  --Color1LightestLowestOpacity: #a7ddd421;
  --Color2: #EB6848;
  --TextTitleColor1: rgb(34, 34, 32);
  --TextTitleColor2: #333132;
  --TextTagColor1: #5E6D81;
  --Color2Darker: #d45d40;
  --ParagraphLineHeight: 24px;
  --ParagraphFontSize: 16px;
  --Color1Darker: #02907f;
  --LightGreenDarker: #405351;
  --MaxPageWidth: 1200px;
  --LinkColor: #1b88bf;
  --PaddingPageTopOne: 50px;
  --GreyColorOne: #4D4D4D;
  --GreyColorTwo: #554c57;
  --LandingMaxPageWidth: 1080px;
  --LandingPagePaddingLeftRight: 2%;
  --LandingSectionPaddingTopBottom: 100px;
  --maxPageWidthBlog: 1400px;
    --paddingLeftRightBlog: 3%;

  @media only screen and (max-width: ${BreakpointTablet + 'px'}){
    --LandingPagePaddingLeftRight: 5%;
    --LandingSectionPaddingTopBottom: 80px;
  }

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    --LandingPagePaddingLeftRight: 7%;
  }
}

.lighter-text{
  font-weight: lighter;
}

.highlighted-text-primary-color{
  color: var(--Color1);
}

.error-text{
  color: var(--ErrorColor);
  font-family: var(--Font1);
  font-size: 12px;
}


.loading-placeholder-text{
  color: var(--Color1);
  font-family: var(--Font2);
}


button:disabled, button[disabled]{
  opacity: 0.5;
}

button{
outline: none;
}

.apply-primary-color-text{
  color: var(--PrimaryColor);
}
#nprogress .bar {
height:2px;
z-index: 9999;
}


#nprogress .bar {
background: var(--PrimaryColor);
z-index: 9999;

}

#nprogress .spinner-icon {
border-top-color: var(--Color1);
border-left-color: var(--Color1);
z-index: 9999;

}


font-smooth: always;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
overflow-y: none;
-webkit-text-stroke: 0.45px;
// or
-webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
// or
text-shadow: #fff 0px 1px 1px;
html {
  box-sizing: border-box;
  font-size: 1rem;

}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  line-height: 2;
  font-family: arial,sans-serif;
  background: white;
}
a {
  text-decoration: none;
  color: ${theme.black};
}

ul{
  padding-left: 0px;
}

/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
  }
  
  #nprogress .bar {
    background: var(--Color2);
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 2px;
  }
  
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--Color1), 0 0 5px var(--Color1);
    opacity: 1.0;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }
  
  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    bottom: 15px !important;
    color: var(--Color1);
    right: 15px !important;
  }
  
  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
  
    border: solid 2px transparent;
    border-top-color: var(--Color1);
    border-left-color: var(--Color1);
    border-radius: 50%;
  
    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
  }
  
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  
  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  
  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

`

export const PageHeaderTypeOne = styled.h1`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 40px;
  color: var(--TextTitleColor1);
  font-family: var(--Font1);
  line-height: 50px;

  @media only screen and (max-width: ${BreakpointTablet - 50 + "px"}) {
    font-size: 35px;
    line-height: 45px;
  }
`

export const PageHeaderTypeTwo = styled.h2`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 28px;
  color: var(--TextTitleColor1);
  font-family: var(--Font1);
  line-height: 40px;
`

export const LabelTypeOne = styled.label`
  font-size: 13px;
  color: var(--GreyColorOne);
  font-weight: bold;
  font-family: var(--Font1);
`

export const PageParagraphTypeOne = styled.p`
  font-size: var(--ParagraphFontSize);
  line-height: var(--ParagraphLineHeight);
  font-family: var(--Font2);
  color: var(--GreyColorTwo);
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop};` : "")}
  ${props => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
  ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : "")}
  ${({marginBottom = "30px"}) => (`margin-bottom: ${marginBottom};`)}

`
export const PageParagraphTypeTwo = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-family: var(--Font1);
  max-width: 400px;
  color: var(--GreyColorTwo);
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop};` : "")}
  ${props =>
    props.paddingBottom ? `padding-top: ${props.paddingBottom};` : ""}
  ${props => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
  ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : "")}
    ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : "")}

`

export const PageHeaderTypeThree = styled.h1`
 
  font-size: 28px;
  color: var(--TextTitleColor2);
  font-family: var(--Font2);
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
  ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : "")}

  @media only screen and (max-width: ${BreakpointTablet + "px"}){
      font-size: x-large;
        
    }
    @media only screen and (max-width: ${BreakpointMobile + "px"}){
      font-size: large;
      line-height: 27px;
              
    }
 
`
export const PageSubHeaderTypeThree = styled.h6`
  margin-top: 0px;
  font-size: 16px;
  color: var(--TextTitleColor2);
  font-family: var(--Font1NotCondensed);
  color: var(--GreyColorOne);
  font-weight: initial;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
  ${props => props.marginTop && `margin-top: ${props.marginTop}px`};
`

export const PageH2TypeOne = styled.h2`
  font-size: 20px;
  font-family: var(--Font1);
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 20px;
  color: var(--TextTitleColor2);
`

export const DividerLineOne = styled.div`
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(51, 49, 50, 0.2);
  margin-top: 6px;
  margin-bottom: 6px;
`

export const UnorderedListTypeOne = styled.ul`
  margin-top: 0px;
`

export const ShowingLimitOutOfCount = styled.div`
  color: var(--GreyColorTwo);
  font-size: calc(var(--ParagraphFontSize) - 1px);
  line-height: calc(var(--ParagraphLineHeight) - 1px);
  font-family: var(--Font1NotCondensed);
`

export const ScrollToAnchor = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -70px;
  background: transparent;
`

export const DisclaimerMessage = styled.p`
  font-size: 10px;
  color: ${props => (props.color ? `${props.color}` : "initial")};
  text-align: center;
  line-height: 14px;
  padding-top: 10px;

  a {
    color: ${props => (props.color ? `${props.color}` : "initial")};
    text-decoration: underline;
  }
`

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#05AA97",
    },
    secondary: {
      main: "#EB6848",
      darker: "#EB6848",
    },
  },
  typography: {
    fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
  },
})
