import React, { useContext, useState } from "react"
import styled from "styled-components"
import AuthUserContext from "../../../../providers/AuthProvider"
import {
  SYMPTOM_CHECKER,
  AILMENTS,
  LOGIN,
  LANDING,
  HERBS,
  ACCOUNT,
  FAVORITES,
  PRIVACY_POLICY,
  FREQUENTLY_ASKED_QUESTIONS,
} from "../../routes"
import { Link } from "gatsby"
import CloseIcon from "react-ionicons/lib/MdClose"
import SidebarLink from "./SidebarLink"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import SidebarOutBoundLink from "./SidebarOutBoundLink"
import { socialMediaUrls } from "../../GlobalStyle"
import SidebarButton from "./SidebarButton"
import { navigate } from "@reach/router"
import PropTypes from "prop-types"

const Container = styled.nav`
  display: flex;
  background: white;
  width: 270px;
  flex-direction: column;
  transition: 0.2s ease-in-out;
  height: 100vh;
  position: fixed;
  z-index: 550;
  transform: ${props =>
    props.opened ? "translateX(0px);" : "translateX(-270px);"};
  .nav-lists-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 50px);
    padding-bottom: 20px;
  }

  .nav-list-group {
    display: flex;
    flex-direction: column;
  }

  .close-wrapper {
    ${props => (props.opened ? "display: flex;" : "display: none;")};

    width: 50px;
    height: 50px;
    position: absolute;
    right: -50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

const UserAvatar = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 12.5px;
  background-repeat: no-repeat;
  background-size: cover;
  ${props =>
    props.src ? `background-image: url(${props.src});` : "background: grey;"}
`

const SidebarHeader = styled.header`
    background: var(--Color1);
    /* ${props =>
      props.loggedIn ? `background: var(--Color1);` : "background: ;"} */
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    font-size: 19px;
    color: white;
    text-shadow: 2px 2px rgba(0,0,0,0.1);

    .sign-up-link{

        color: var(--Color2);
    }

    .users-name{
        color: inherit;
        a{
            text-decoration: underline;
            color: inherit;

            :hover{
                opacity: 0.8;
            }
        }

      
    }
`

const NavListSectionTitle = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  width: 100%;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-right: 14px;
  justify-content: space-between;
  color: rgba(77, 77, 77, 0.5);
  font-size: 13px;
  font-family: var(--Font2);
  text-transform: uppercase;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  background: rgba(204, 204, 204, 0.5);
`

const NavList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

function Sidebar({ opened, setOpen }) {
  const {
    user,
    handleSignOut,
    openSignInModal,
    userAvatar,
    isTheUserLoggedIn,
  } = useContext(AuthUserContext)

  const handleSignOutButton = () => {
    handleSignOut()
    setOpen(false)
  }

  const handleNavigationToSymptomChecker = () => {
    if (user.id !== "") {
      navigate(SYMPTOM_CHECKER)
    } else {
      openSignInModal(
        "find Natural Remedies based on your symptoms",
        SYMPTOM_CHECKER
      )
    }
  }

  return (
    <Container opened={opened}>
      <div onClick={() => setOpen(false)} className="close-wrapper">
        <CloseIcon color="white" size={30} />
      </div>
      <SidebarHeader loggedIn={user.first_name ? true : false}>
        {isTheUserLoggedIn ? <UserAvatar src={userAvatar} /> : ""}
        <div className="users-name">
          Hello,{" "}
          <strong>
            {" "}
            {user.first_name ? (
              <Link to={ACCOUNT}>{user.first_name}</Link>
            ) : (
              <Link to={LOGIN}> Login here</Link>
            )}
          </strong>
        </div>
      </SidebarHeader>
      <section className="nav-lists-section">
        <NavList>
          <SidebarLink title="Home" path={LANDING} />
          <SidebarButton
            onClick={() => handleNavigationToSymptomChecker()}
            title="Symptom Checker"
          />
          <SidebarLink title="Herbs" path={HERBS} />
          <SidebarLink title="Ailments" path={AILMENTS} />
          <SidebarLink title="Your Favorites" path={FAVORITES} />
          {isTheUserLoggedIn ? (
            <SidebarLink title="Account" path={ACCOUNT} />
          ) : (
            ""
          )}
        </NavList>
        <div className="nav-list-group">
          <NavList>
            <NavListSectionTitle>Follow us on social media</NavListSectionTitle>
            <SidebarOutBoundLink
              title="Facebook"
              path={socialMediaUrls.facebook}
            />
            <SidebarOutBoundLink
              title="TikTok"
              path={socialMediaUrls.tiktok}
            />
            <SidebarOutBoundLink
              title="YouTube"
              path={socialMediaUrls.youtube}
            />
            <SidebarOutBoundLink
              title="Instagram"
              path={socialMediaUrls.instagram}
            />
            <SidebarOutBoundLink
              title="Twitter"
              path={socialMediaUrls.twitter}
            />
          </NavList>
          <Divider />
          <NavList>
            <SidebarLink title="Privacy Policy" path={PRIVACY_POLICY} />
            <SidebarLink title="FAQ" path={FREQUENTLY_ASKED_QUESTIONS} />
            {user.first_name ? (
              <SidebarButton
                onClick={() => handleSignOutButton()}
                title="Logout"
                path={ACCOUNT}
              />
            ) : (
              null
            )}
          </NavList>
        </div>
      </section>
    </Container>
  )
}

Sidebar.defaultProps = {
  opened: false,
}

Sidebar.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
