import React from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components'

const StyledMuiAlert = styled(MuiAlert)`
display: flex;
align-items: flex-start;
  user-select: text;
  .MuiAlert-message{
    padding-bottom: 0px;
  }
  .MuiAlert-icon{
    margin-right: 5px;
    margin-top: 2px;
  
  }
  ul{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px;
    list-style: none;
    li{
      font-weight: lighter;

    }
  }
`

function MaterialAlert(props) {
  return <StyledMuiAlert elevation={6} variant="filled" {...props} />;
}

export default MaterialAlert