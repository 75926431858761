import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import LoginSection from './LoginSection'
import RegisterSection from './RegisterSection'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { loginUser, registerUser } from '../../../services/user.services'
import { BreakpointMobile } from '../../../components/Layout/GlobalStyle'
import { navigate } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery';



const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);


const Container = styled.div`
  padding: 10px 22px 22px 22px;
  display: flex;
  position: relative;
  /* height: 463px; */
  

  .extra-space{
    display: none;

  }

  #horizontalDivider{
    display: none;
  }

  #verticalDivider{

  }

 

  @media screen and (max-width: ${BreakpointMobile + 'px'}){
    max-height: 100%;
    .extra-space{
      position: relative;
      display: block;
      min-height: 20px;
      background: transparent;
    }

    #horizontalDivider{
    display: initial !important;

  }
  #verticalDivider{
    display: none;
  }


    flex-direction: column;
    padding: 15px !important;
    width: 100%;


  }
`

const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  width: 234px;
  position: relative;

  .column-one-header{
    font-size: 20px;
    font-weight: bold;
    font-family: var(--Font1NotCondensed);
    margin-top: 0px;
    line-height: 28px;
    
    .sign-in-action{
      color: var(--Color1);
    }
  }

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
   

    width: initial;
    height: initial;
    min-height: ${props => `${props.minHeight + 'px'}`};

    /* max-height: 470px; */
  }

`
const ColumnTwo = styled.div`
 display: flex;
  flex-direction: column;
  width: 234px;
  position: relative;

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    width: 100%;
    margin-bottom: 20px !important;
    /* max-height: 470px; */

    .column-two-header{
    font-size: 15px;
    font-weight: bold;
    position: relative;
    line-height: 26px;
    margin-bottom: 15px;
    color: var(--GreyColorOne);
  }


  }

  .column-two-header{
    font-size: 20px;
    font-weight: bold;
    position: relative;
    font-family: var(--Font1);
    line-height: 26px;
    max-height: 100%;
    margin-bottom: 25px;
    color: var(--GreyColorOne);
  }

  .column-two-ul{
    margin-top: 0px;

    .check-img{
      min-width: 22px;
      min-height: 22px;
      margin-right: 10px;
    }

    li{
      display: flex;
      align-items: top;

      font-family: var(--Font1NotCondensed);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
      color: var(--GreyColorOne);

  
      }

      
    }
  
 

`


const useStyles = makeStyles((theme) => ({
  divider: {
    marginLeft: 35,
    marginRight: 35
  },
  dividerHorizontal: {
    marginTop: 20,
    position: 'relative',
    marginBottom: 20,
    background: "4D4D4D",
    width: '100%'
  },
  submitForm: {
    fontFamily: "var(--Font1NotCondensed)",
    textTransform: 'initial',
    fontWeight: 'bold',
    minHeight: 38


  },
  toggleButton: {
    textTransform: 'initial',
    color: '#4D4D4D',
    borderRadius: 4,
    fontWeight: 'bold',
    minHeight: 38,
    padding: 5,
    borderColor: "#4D4D4D",
    borderWidth: 1,
    borderStyle: 'solid'
  }
}));

function SignInModal(props) {
  const modalBodyRef = useRef()
  const scrollToRef = useRef()
  const [showLogin, setShowLogin] = useState(false)
  const { setOpen, open, signInAction, setUser, onClose, signInRedirect } = props;
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const classes = useStyles()
  const [errors, setErrors] = useState({})


  const handleLoginSubmit = () => {
    setSubmitting(true)
    loginUser({ email, password })
      .then(decoded => {
        setSubmitting(false)

        setUser(decoded);
        onClose()

        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        setErrors({})

        if (signInRedirect) {
          navigate(signInRedirect)
        }
      })
      .catch(err => {
        setSubmitting(false)

        setErrors(err.response.data)

      })
  }

  const handleRegisterSubmit = () => {
    setSubmitting(true)
    registerUser({ email, password, first_name, last_name })
      .then(decoded => {
        setSubmitting(false)
        setUser(decoded);

        onClose()
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        setErrors({})
        if (signInRedirect) {
          navigate(signInRedirect)
        }
      })
      .catch(err => {
        setSubmitting(false)
        setErrors(err.response.data)

        throw err
      })
  }

  const handleToggleSection = (booleanValue) => {
    setShowLogin(booleanValue)
    setErrors({})
    const widthOfModal = modalBodyRef.current.clientWidth;
    if (widthOfModal <= 500) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }


  return (
    <Dialog onClose={onClose} aria-labelledby="signInModal" open={open}>
      <Container ref={modalBodyRef} >

        <ColumnOne id="signInModalColumnOne" minHeight={showLogin && !isEmpty(errors) ? 330 : showLogin && isEmpty(errors) ? 270 : !isEmpty(errors) ? 530 : 450}>
          <h1 ref={scrollToRef} className="column-one-header">
            Login {signInAction ? <>to <span className="sign-in-action">{signInAction}</span>   </> : ""}

          </h1>
          {showLogin ? <LoginSection handleSubmit={handleLoginSubmit} classes={classes} errors={errors} submitting={submitting} setEmail={setEmail} setPassword={setPassword} email={email} password={password} /> : <RegisterSection handleSubmit={handleRegisterSubmit} errors={errors} classes={classes} setLastName={setLastName} setPassword={setPassword} setFirstName={setFirstName} setEmail={setEmail} email={email} first_name={first_name} last_name={last_name} password={password} submitting={submitting} last_name={last_name} first_name={first_name} email={email} password={password} />}
          
        </ColumnOne>




        <Divider id="verticalDivider" className={classes.divider} orientation="vertical" flexItem />
        <Divider id="horizontalDivider" className={classes.dividerHorizontal} />
        <ColumnTwo>
          <h1 className="column-two-header">
            Not a member yet? Here are
            some of the things you can do
            with a FREE account:
          </h1>
          <ul className="column-two-ul">
            <li><img className="check-img" width={22} height={22} src="https://res.cloudinary.com/landonwebdev/image/upload/v1594166973/checkmark-circle-green.svg" />Find natural remedies based on your symptoms using our symptom checker</li>

            <li><img className="check-img" width={22} height={22} src="https://res.cloudinary.com/landonwebdev/image/upload/v1594166973/checkmark-circle-green.svg" /> Share your expertise in natural remedies</li>
            <li><img className="check-img" width={22} height={22} src="https://res.cloudinary.com/landonwebdev/image/upload/v1594166973/checkmark-circle-green.svg" />Vote on your favorite remedies</li>
            <li><img className="check-img" width={22} height={22} src="https://res.cloudinary.com/landonwebdev/image/upload/v1594166973/checkmark-circle-green.svg" /> Connect with other people who
            share your passion for alternative
 medicine</li>
          </ul>

          {showLogin ?

            <Button className={classes.toggleButton} onClick={() => handleToggleSection(false)}>
              Create free account
          </Button> :

            <Button className={classes.toggleButton} onClick={() => handleToggleSection(true)}>
              Already have an account?  Login here
          </Button>
          }
          {/* <br /> */}
          <div className="extra-space">
          
          </div>
        </ColumnTwo>


      </Container>

    </Dialog>

  )
}

SignInModal.defaultProps = {
  setOpen: () => { },
  open: false,
  signInAction: "",
  setUser: () => { },
  onClose: () => { },
  signInRedirect: ""
}


export default SignInModal