import React, {useState, useContext} from 'react'
import styled from 'styled-components'
import { theme, SpyGlassIcon, BreakpointTablet, BreakpointMobile } from '../GlobalStyle'
import {Link} from 'gatsby'
import { navigate } from "@reach/router"  
import AuthUserContext from '../../../providers/AuthProvider'
import InputBase from '@material-ui/core/InputBase';

const Container = styled.div`
display: flex;
    height: 33px;
    width: inherit;
    transition: 0.2s ease-in-out;

    #searchDesktop{
        display: flex;
        border: 0px solid transparent;
        justify-content: space-between;
        border-radius: 4px;
        height: inherit;
        width: inherit;
        background: transparent;
        overflow: hidden;
        background: white;

        .header-search-input{
            width: 100%;
            border: 0px solid transparent;
            border-radius: 4px;
            padding-left: 10px;
            font-family: var(--Font2);
            font-weight: normal;
            padding-right: 10px;
            outline: none;
            border-radius: 4px 0px 0px 4px;
        }

        .close-search-button{
            background: transparent;
            border: 1px solid transparent;
            color: grey;
            font-size: 20px;
            width: 35px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.1 ease-in-out;
            

            :hover{
                color: #F44336;
                cursor: pointer;
            }
        }

        .header-search-button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            background: var(--Color2);
            border: 0px solid transparent;
            border-radius: 0px 4px 4px 0px;
            height: 33px;
            :hover{
                background: #D96043;
                cursor: pointer;
            }

            :active{
                background: #CE5B3F;
            }

            svg{
        
                stroke: #fff !important;
            }
        }

        @media screen and (max-width: ${BreakpointTablet + 'px'}) {
            transition: 0.2s ease-in-out;
            width: 0px;
            ${props => props.mobileSearchOpen? 'width: initial;' : 'width: 0px;'}
        }
    }

    @media screen and (max-width: ${BreakpointMobile + 'px'}) {
        padding-left: 10px;
    }

    #searchMobile{
        display: none;
        transition: 0.2s ease-in-out;

    

        @media screen and (max-width: ${BreakpointTablet + 'px'}) {
            height: inherit;
         
            width: 100%;
            ${props => props.mobileSearchOpen? 'display: none;' : 'display: initial;'}


            .open-search-wrapper{
                display: flex;
                flex-direction: row-reverse;
                position: relative;
                height: inherit;
                height: 33px;
                width: 100%;
                

            }
            .open-search{
                background: transparent;
                cursor: pointer;
                height: inherit;
                float: right;
                transition: 0.2s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                border: 0px solid transparent;
            }
        }
    }

    



`

function HeaderSearch(props) {
    let {user} = useContext(AuthUserContext)
    let [query, setQuery] = useState("")
    let [openMobileSearch, setOpenMobileSearch] = useState(false)

    const onCloseSearch = () => {
        setOpenMobileSearch(false)
        setQuery("")
    }

    const handleKeyDown = (e) => {
        let { keyCode } = e;
        if (keyCode === 13) {
          navigate(`/search?q=${query}`)
        }
      }
    

    return (
        <Container loggedIn={user.first_name? true: false} mobileSearchOpen={openMobileSearch}>
            <div id="searchDesktop">
                <InputBase size="small" onKeyUp={(e) => handleKeyDown(e)} onChange={(e) => setQuery(e.target.value)} placeholder="Search here" className="header-search-input" />
                {openMobileSearch? 
                    <button className="close-search-button" onClick={() => onCloseSearch()}>&times;</button> : ""
                }
                
            
                    <button onClick={() => (navigate(`/search?q=${query}`))} className="header-search-button">
                        <SpyGlassIcon />
                    </button>

            </div>
            <div id="searchMobile">
                {!openMobileSearch? 
                <div className="open-search-wrapper">

                    <button onClick={() => setOpenMobileSearch(true)} className="open-search">
                        <SpyGlassIcon />
                    </button>

                </div> : ""}
            </div>
        </Container>
    )
}

export default HeaderSearch