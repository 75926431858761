import axios from "./axios";
import jwt_decode from "jwt-decode";
import { getHeaders } from "../components/Layout";


export function saveItem(item, selectedValue) {
    try {
        localStorage.setItem(item, selectedValue);
    } catch (error) {
        console.error("AsyncStorage error: " + error.message);
    }
}

export function removeItem(item) {
    try {
        localStorage.removeItem(item);
    } catch (error) {
        console.error("AsyncStorage error: " + error.message);
    }
}


export const createUser = (body) => {
    return axios
        .post(`/api/users/create`, body)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getUser = () => {
    return axios
        .get(`/api/users`)
        .then(res => res)
        .catch(err => {
            throw err
          
        })
};

export const getAuthorByUserId = (authorId) => {
    return axios
        .get(`/api/users/author/${authorId}`)
        .then(res => res)
        .catch(err => {
            throw err
          
        })
};


export const getUserPublicInformation = (id) => {
    return axios
        .get(`/api/users/public/${id}`)
        .then(res => res)
        .catch(err => {
            throw err
          
        })
};


export const getUserPrivateInformation = (id) => {
    return axios
        .get(`/api/users/private/${id}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
          
        })
};


export const getUserByID = (id) => {
    return axios
        .get(`/api/users?id=${id}`)
        .then(res => res)
        .catch(err => {
            throw err
          
        })
};


export const updateUserPassword = (body) => {
    return axios
        .post(`/api/users/password`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
         
        })
}

export const updateUserLastTimeActive = (body) => {
    return axios
        .patch(`/api/users/last-time-active`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
           
        })
}



export const updateUsersFirstLastName = (body) => {
    return axios
        .post(`/api/users/update/name`, body, getHeaders())
        .then(res => {
            if (res.status >= 200 && res.data && res.data.refreshUserToken) {
                const { token } = res.data;
                saveItem("jwt", token);
            }
            return res
        })
        .catch(err => {
            throw err
        })
}



export const updateUsersEmail = (body) => {
    return axios
        .post(`/api/users/update/email`, body, getHeaders())
        .then(res => {
            if (res.status >= 200 && res.data && res.data.refreshUserToken) {
                const { token } = res.data;
                saveItem("jwt", token);
            }
            return res
        })
        .catch(err => {
            throw err
        })
}




export const updateUser = (body) => {
    return axios
        .post(`/api/users/update`, body, getHeaders())
        .then(res => {
            if (res.status >= 200 && res.data && res.data.refreshUserToken) {
                const { token } = res.data;
                saveItem("jwt", token);
            }
            return res
        })
        .catch(err => {
          
        })
}

export const getUserPermissions = (userid) => {
    return axios
        .get(`/api/users/permission/${userid}`, getHeaders())
        .then(res => res)
        .catch(err => {
          
        })
}


export const getUserAvatarByUserID = (userid) => {
    return axios
        .get(`/api/users/avatar/${userid}`, getHeaders())
        .then(res => res)
        .catch(err => {
          throw err
        })
}






export const resetPassword = (body) => {
    return axios
        .post(`/api/users/reset-password`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        });
};


export const updateUserPermissions = (body) => {
    return axios
        .post(`/api/users/permissions`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        });
}


export const updateUserAvatar = (body) => {
    return axios
        .patch(`/api/users/update/avatar`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const loginUser = body => {
    return axios
        .post(`/api/users/login`, body)
        .then(({ status, data }) => {
            if (status === 200) {
                const { token } = data;
                saveItem("jwt", token);
                let { email, password } = body;
                saveItem("email", email);

                // Decode token to get user data
                const decoded = jwt_decode(token);
                return decoded;
            }
        })
        .catch(err => {
            throw err
        })
};



export const logoutUser = () => {
    return axios
        .get(`/api/users/logout`)
        .then(res => {
            let { status, data } = res;
            if (status === 200) {
                saveItem("jwt", "");
                return res;
            }
        })
        .catch(err => err);
};

export const registerUser = body => {
    let payload = {...body, signedUpOnPlatform: "findanherb.com"}
    console.log(payload, 'this is paylaod in register user')
    return axios
        .post(`/api/users/register`, payload)
        .then(({ status, data }) => {
            if (status === 200) {
                const { token } = data;
                saveItem("jwt", token);
                let { email, password } = body;
                saveItem("email", email);

                // Decode token to get user data
                const decoded = jwt_decode(token);
                return decoded;
            }
        })
        .catch(err => {
            throw err
        });
};