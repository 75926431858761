import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { seoPropTypes } from "../../../types/SeoPropTypes"

function SEO({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  type,
  productBrand,
  productAvailability,
  productCondition,
  productPriceAmount,
  productPriceCurrency,
  productRetailerItemID,
  productCategory,
  isProduct,
  imageLink,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            twitterUsername
            twitter
            titleAlt
            title
            siteUrl
            siteLanguage
            ogLanguage
            pathPrefix
            keywords
            headline
            facebook
            description
            banner
            author
          }
        }
      }
    `
  )

  let metaDescription = description || site.siteMetadata.description
  let metaType = type || "website"
  let metaTitle = site.title || title

  let defaultImage = {
    src: `${site.siteMetadata.banner}`,
    width: 1200,
    height: 628,
  }
  const image = metaImage && metaImage.src ? metaImage : defaultImage
  const canonical = pathname
    ? `${site.siteMetadata.siteUrl}${pathname}`
    : site.siteMetadata.siteUrl


      let jsonLDSchemaMarkup = {
    "@context": "https://schema.org",
    "@type": "website",
    "description": description,
    "image": image.src,
    "url": canonical,
    "name": title
  };
  if(isProduct){
    jsonLDSchemaMarkup = {
      "@context": "https://schema.org",
      "@type": "Product",
      "@productID": productRetailerItemID,
      "description": description,
      "image": imageLink,
      "url": canonical,
      "brand": productBrand,
      "offers": [{
        "@type": "Offer",
        "price": productPriceAmount,
        "priceCurrency": productPriceCurrency,
  "itemCondition": "https://schema.org/NewCondition",
  "availability": "https://schema.org/InStock"
      }],
      "additionalProperty": [{
        "@type": "PropertyValue",
        "propertyID": "item_group_id",
        "value": productCategory
      }],
      "name": title
  }}
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image.src,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },

        {
          property: `og:title`,
          content: title,
        },
        {
          name: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:description`,
          content: metaDescription,
        },

        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:url`,
          content: `${canonical}`,
        },

        {
          name: `og:type`,
          content: metaType,
        },
        {
          property: "fb:app_id",
          content: "615214672609603",
        },
        {
          property: "fb:pages",
          content: "101407651472941",
        },
        {
          name: "keywords",
          content: "herbs, natural remedies, ailments",
        },
      ]
        .concat(
          defaultImage
            ? [
                {
                  property: "og:image",
                  content: image.src,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    >
      {productBrand ? (
        <meta property="product:brand" content={productBrand} />
      ) : null}
      {productAvailability ? (
        <meta property="product:availability" content={productAvailability} />
      ) : null}
      {productCondition ? (
        <meta property="product:condition" content={productCondition} />
      ) : null}
      {productPriceAmount ? (
        <meta property="product:price:amount" content={productPriceAmount} />
      ) : null}
      {productPriceCurrency ? (
        <meta
          property="product:price:currency"
          content={productPriceCurrency}
        />
      ) : null}
      {productRetailerItemID ? (
        <meta
          property="product:retailer_item_id"
          content={productRetailerItemID}
        />
      ) : null}
      {productCategory ? (
        <meta property="product:item_group_id" content={productCategory} />
      ) : null}
      {productCategory ? (
        <meta property="google_product_category" content={productCategory} />
      ) : null}

      {imageLink ? <meta property="image_link" content={imageLink} /> : null}
      {isProduct ? <meta property="description" content={imageLink} /> : null}
      {productPriceAmount ? (
        <meta property="price" content={productPriceAmount} />
      ) : null}
      {isProduct ? <meta property="link" content={canonical} /> : null}

      {/* {isProduct ? (
        <>
          <div itemScope itemType="http://schema.org/Product">
            <meta itemProp="brand" content={productBrand} />
            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="productID" content={productRetailerItemID} />
            <meta itemProp="url" content={canonical} />
            <meta itemProp="image" content={imageLink} />
            <div
              itemProp="value"
              itemScope
              itemType="http://schema.org/PropertyValue"
            />
            <span itemProp="propertyID" content="item_group_id"></span>
            <meta itemProp="value" content={productCategory}></meta>
          </div>
          <span itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <link itemProp="availability" href={productAvailability} />
            <link itemProp="itemCondition" href={productCondition} />
            <meta itemProp="price" content={productPriceAmount} />
            <meta itemProp="priceCurrency" content={productPriceCurrency} />
          </span>
        </>
      ) : ""} */}

      <script type={"application/json+ld"}>
        {JSON.stringify(jsonLDSchemaMarkup)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: "",
}

SEO.propTypes = seoPropTypes

export default SEO
