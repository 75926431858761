import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Header from './NormalLayout/Header';
import Footer from './NormalLayout/Footer';
import { PaddingLeftRightDesktop, GlobalStyle, theme, muiTheme } from './GlobalStyle';
import SEO from './SEO'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { ErrorsProvider } from '../../providers/ErrorsProvider';
import { AuthProvider } from '../../providers/AuthProvider';
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import CustomerChat from '../common/Facebook/CustomerChat';
import {useLocation} from '@reach/router'
import queryString from 'query-string'
import isEmpty from '../../utils/isEmpty';
import { seoPropTypes } from '../../../types/SeoPropTypes';


export function getJWT() {
    if (typeof window != undefined) {
        return localStorage.getItem("jwt")
            ? localStorage.getItem("jwt")
            : "";
    }
}
export const getHeaders = () => {
    if (typeof window != "undefined") {
        let headers = {
            Authorization: getJWT(),
            "Content-Type": "application/json",
        }

        return {
            headers
        }
    }
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
`


const Wrapper = styled.div`
    display: block;
    width: 100%;
    /* max-width: var(--MaxWidthPage); */
`

const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  max-width: var(--MaxWidthPage);
  margin: auto;
  ${props => props.hideHeader? "padding-top: 0px;": "padding-top: 50px;"}
  padding-left: ${props => props.paddingLeft ? `${props.paddingLeft};` : `${PaddingLeftRightDesktop};`};
  padding-right: ${props => props.paddingRight ? `${props.paddingRight};` : `${PaddingLeftRightDesktop};`};
  height: inherit;
`

const Layout = ({ children, seoProps, contentStyleProps }) => {

  const location = useLocation()
  let search = location.search ? queryString.parse(location.search) : {}

  search.hideHeader = !isEmpty(search.hideHeader) ? search.hideHeader : false;
  search.hideFooter = !isEmpty(search.hideFooter) ? search.hideFooter : false;

  if(search.hideHeader === "true"){
      search.hideHeader = true;
  }

  if(search.hideFooter === "true"){
    search.hideFooter = true;
}


    return (
        <>
            <SEO {...seoProps} />
            <ThemeTopLayout theme={muiTheme}>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                    <ErrorsProvider>
                        <AuthProvider>
                            <Container>
                                <Header hideHeader={search.hideHeader} />
                                <Content hideHeader={search.hideHeader} {...contentStyleProps} >
                                    <Wrapper>
                                        {children}
                                    </Wrapper>
                                </Content>
                                <Footer hideFooter={search.hideFooter}  />
                            </Container>
                        </AuthProvider>
                    </ErrorsProvider>
                </ThemeProvider>
            </ThemeTopLayout>
            {/* <CustomerChat /> */}
        </>
    );
};


Layout.defaultProps = {
    contentStyleProps: {
        paddingLeft: "3%",
        paddingRight: "3%"
    }
}

Layout.propTypes = {
    contentStyleProps: PropTypes.shape({
        paddingLeft: PropTypes.string,
        paddingRight: PropTypes.string
    }),
    children: PropTypes.node.isRequired,
    seoProps: seoPropTypes
}





export default Layout;
