import PropTypes from 'prop-types'


export const seoPropTypes = PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    pathname: PropTypes.string,
    image: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        src: PropTypes.string
    }),
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    productPriceAmount: PropTypes.string,
    productPriceCurrency: PropTypes.string,
    productCondition: PropTypes.string,
    productAvailability: PropTypes.string,
    productBrand: PropTypes.string,
    productRetailerItemID: PropTypes.string,
    productCategory: PropTypes.string,
    productImage: PropTypes.string,
    isProduct: PropTypes.bool,
    imageLink: PropTypes.string
  })