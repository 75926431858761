import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import ArrowForward from 'react-ionicons/lib/MdArrowForward'
import { ChevronForwardIcon } from '../../GlobalStyle'


const SidebarLinkStyled = styled(Link)`
    height: 43px;
    padding-left: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 14px;
    justify-content: space-between;
    .link-title{
        color: #4D4D4D;
        font-family: var(--Font2);
        font-size: 14px;
    }




    :hover{
        transition: 0.2s ease-in-out;

        background: rgba(0, 0, 0, 0.05);
    }

    :active{
        background: rgba(0, 0, 0, 0.3);

    }
`

function SidebarLink(props) {
    let {path, title} = props
    return (
        <SidebarLinkStyled to={path}>
            <div className="link-title">

            {title}
            </div>
            <div className="go-to-icon">
                <ChevronForwardIcon />
            </div>
        </SidebarLinkStyled>
    )
}

SidebarLink.defaultProps = {
    path: "",
    title: "Title"
}


export default SidebarLink