

// const axiosConfig = {
//   baseURL: `http://localhost:5000`

import { baseURL } from "../baseURL";

// }
const axiosConfig = {
  baseURL: baseURL
}

 function getJWT() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("jwt")
      ? localStorage.getItem("jwt")
      : "";
  }
}


export {
  axiosConfig,
  getJWT
}

