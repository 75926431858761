import React, { useState, useContext, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from "styled-components";

export const LoginRegisterContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  height: 100%;
  .form-title{
    position: relative;
    font-family: var(--Font1);
    margin-bottom: 10px;
    color: var(--GreyColorOne);
    font-weight: bold;
  }
  .input-wrapper{
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .title-and-inputs{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
`

function LoginSection(props) {
  let { handleSubmit, classes, password, email, submitting, errors, setEmail, setPassword } = props;

  const handleKeyDown = (e) => {
    let { keyCode } = e;
    if (keyCode === 13) {
      handleSubmit()
    }
  }

  return (
    <LoginRegisterContainer>
      <div className="title-and-inputs">

        <div className="form-title">Account login:</div>
        <div data-private className="input-wrapper">
          <TextField disabled={submitting} size="small" value={email} onChange={(e) => setEmail(e.target.value)} helperText={errors.email ? errors.email : ""} error={errors.email ? true : false} id="emailFormInput" label="Email" variant="outlined" />
          <TextField disabled={submitting} size="small" type="password" onKeyUp={(e) => handleKeyDown(e)} value={password} onChange={(e) => setPassword(e.target.value)} helperText={errors.password ? errors.password : ""} error={errors.password ? true : false} id="passwordFormInput" label="Password" variant="outlined" />
        </div>
      </div>
      <Button className={classes.submitForm} disableElevation variant={"contained"} color="secondary" disabled={submitting} fullWidth onClick={() => handleSubmit()} >
        {submitting ? "Logging in" : "Login"}
      </Button>

    </LoginRegisterContainer>
  )
}

LoginSection.defaultProps = {
  handleSubmit: () => {},
  password: "",
  email: "",
  submitting: false,
  setEmail: () => {},
  setPassword: () => {}
}


export default LoginSection