export const HERBS = "/herbs"
export const HERB = "/herb"
export const AILMENTS = "/ailments"
export const AILMENT = "/ailment"
export const PRIVACY_POLICY = "/privacy-policy"
export const USER_AGREEMENT = "/user-agreement"
export const DISCLAIMER = "/disclaimer"
export const PRODUCT = "/product"
export const SYMPTOM_CHECKER = "/symptom-checker"
export const SYMPTOM_CHECKER_SELECT_SYMPTOMS = "/symptom-checker/select-symptoms"
export const SYMPTOM_CHECKER_AILMENT_RESULTS = "/symptom-checker/ailment-results"
export const LANDING = "/"
export const REGISTER = "/register"
export const LOGIN = "/login"
export const ACCOUNT = "/account"
export const CHANGE_USERS_NAME = "/account/name"
export const CHANGE_USERS_EMAIL = "/account/email"
export const CHANGE_USERS_PASSWORD = "/account/password"
export const FAVORITES = "/favorites"
export const FREQUENTLY_ASKED_QUESTIONS = "/faq"
export const CREATE_HERB_REVIEW = "/create-herb-review"
export const SUBMITTED_HERB_REVIEW = "/create-herb-review/thank-you"
export const APP_PAGE = "/app"
export const GET_APP = "/download-app"
export const ABOUT = "/about"
export const BLOG = "/blog"
export const BLOG_POST = "/blog/post"
export const AUTHOR = "/blog/author"


export const NORMAL_ROUTES = [APP_PAGE,PRODUCT, GET_APP ,SYMPTOM_CHECKER_SELECT_SYMPTOMS, SYMPTOM_CHECKER_AILMENT_RESULTS, SYMPTOM_CHECKER, SUBMITTED_HERB_REVIEW, CREATE_HERB_REVIEW, FREQUENTLY_ASKED_QUESTIONS, FAVORITES, CHANGE_USERS_NAME, HERB, HERBS, AILMENT, AILMENTS, PRIVACY_POLICY, LANDING]
export const BARE_ROUTES = [LOGIN, REGISTER]