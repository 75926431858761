
import LogRocket from 'logrocket';

import setupLogRocketReact from 'logrocket-react';

let logRocket = null;
// only initialize when in the browser
if (typeof window !== 'undefined') {
    LogRocket.init('vue9gw/findanherb-client-portal');

    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket);
    logRocket = setupLogRocketReact(LogRocket);
}
export default logRocket;
