import React, { createContext, useState, useEffect, useCallback } from "react"
import { getJWT } from "../../components/Layout"
import jwt_decode from "jwt-decode"
import { navigate } from "@reach/router"
import { LANDING } from "../../components/Layout/routes"
import {
  updateUserLastTimeActive,
  getUserAvatarByUserID,
  saveItem,
  removeItem,
} from "../../services/user.services"
import SignInModal from "./SignInModal"
import BecomeAMemberModal from "./BecomeAMemberModal"
import logRocket from "../../utils/logrocket"

let AuthUserContext
const { Provider, Consumer } = (AuthUserContext = createContext())
let defaultUser = {
  role: 0,
  id: "",
  permissions: [],
  first_name: "",
  last_name: "",
}
const AuthProvider = ({ children }) => {
  const initialUserAvatar =
    typeof window !== "undefined" && window.localStorage
      ? window.localStorage.getItem("currentUserAvatar")
      : ""
  const initialTimesVisitedWebsiteArray =
    typeof window !== "undefined" && window.localStorage
      ? window.localStorage.getItem("currentWebsiteVisitsArray")
      : []

  const [user, setUser] = useState(defaultUser)
  const [ready, setReady] = useState(false)
  const [signInModelOpened, setSignInModelOpened] = useState(false)
  const [signInAction, setSignInAction] = useState("Leave Review")
  const [signInRedirect, setSignInRedirect] = useState("")
  const [userAvatar, setUserAvatar] = useState(initialUserAvatar)
  const [timesVisitedWebsite, setTimesVisitedWebsite] = useState([])
  const [membersOnlyModelOpened, setMembersOnlyModelOpened] = useState(false)


  const getUserAvatar = useCallback(() => userid => {
    getUserAvatarByUserID(userid)
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem("currentUserAvatar", res.data)
          setUserAvatar(res.data)
        }
      })
      .catch(err => {
        throw err
      })
  }, [])

  const loadData = useCallback(async () => {
    let token = await getJWT()
    if (token) {
      const decoded = jwt_decode(token)
      if (decoded) {
        setUser(decoded)
        setReady(true)
        getUserAvatar(decoded.id)
        if (logRocket) {
          logRocket.identify(decoded.id, {
            name: `${decoded.first_name} ${decoded.last_name}`,
            email: decoded.email,
            id: decoded.id,
          })
        }
        updateUserLastTimeActive({ lastUsedPlatform: "findanherb.com" })
          .then(res => {
            if (res.status === 204) {
              // making sure to remove anything that
              // they should not be seeing if they are logged in
              if (initialTimesVisitedWebsiteArray) {
                removeItem("currentWebsiteVisitsArray")
                setTimesVisitedWebsite([])
                setMembersOnlyModelOpened(false)
              }
            }
          })
          .catch(err => {
            if (err.response.data === "Unauthorized") {
              handleSignOut()
            } else {


              throw err
            }

          })
      }
    }
  }, [getUserAvatar, initialTimesVisitedWebsiteArray])


  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && !user.id) {
      loadData(isSubscribed)
    }
    return () => (isSubscribed = false)
  }, [user, loadData])

  const triggerVisitToWebsiteWithOutMembership = slugOfSchemaItem => {
    if (user.id) {
      console.log("there is no user")
    } else {
      if (initialTimesVisitedWebsiteArray) {
        var d = new Date()
        var n = d.getMonth()
        let tempInitialTimesVisited = JSON.parse(
          initialTimesVisitedWebsiteArray
        )
        let existingSlugForItem = tempInitialTimesVisited.find((item => item.slug === slugOfSchemaItem));


        // Check to see if they have already visited this page
        // if they have it should not count against them for 
        // viewing it again
        if (!existingSlugForItem) {
          tempInitialTimesVisited.push({ date: n, slug: slugOfSchemaItem })
        }
        let stringifyTempTimes = JSON.stringify(tempInitialTimesVisited)
        saveItem("currentWebsiteVisitsArray", stringifyTempTimes)
        setTimesVisitedWebsite(tempInitialTimesVisited)
      } else {
        var d = new Date()
        var n = d.getMonth()
        let tempInitialTimesVisited = [{ date: n, slug: slugOfSchemaItem }]
        let stringifyTempTimes = JSON.stringify(tempInitialTimesVisited)
        saveItem("currentWebsiteVisitsArray", stringifyTempTimes)
        setTimesVisitedWebsite(tempInitialTimesVisited)
      }
    }
  }



  const openSignUpToBecomeAMember = () => {
    setMembersOnlyModelOpened(true)
  }

  const closeSignUpToBecomeAMember = () => {
    if (initialTimesVisitedWebsiteArray) {
      removeItem("currentWebsiteVisitsArray")

    }
    setTimesVisitedWebsite([])
    setMembersOnlyModelOpened(false)
  }

  const handleSignOut = () => {
    localStorage.removeItem("jwt")
    localStorage.removeItem("email")
    localStorage.removeItem("password")
    setUser(defaultUser)
  }

  let isTheUserLoggedIn = user.id !== ""

  const openSignInModal = (action, path) => {
    setSignInAction(action)
    setSignInModelOpened(true)
    if (path) {
      setSignInRedirect(path)
    }
  }

  const closeSignInModal = () => {
    setSignInAction("")
    setSignInModelOpened(false)
    if (signInRedirect) {
      setSignInRedirect("path")
    }
  }

  let remainingVisitsToWebsite = 3 - timesVisitedWebsite.length

  return (
    <Provider
      value={{
        openSignInModal,
        getUserAvatar,
        remainingVisitsToWebsite,
        triggerVisitToWebsiteWithOutMembership,
        setUserAvatar,
        userAvatar,
        isTheUserLoggedIn,
        user,
        setUser,
        ready,
        setReady,
        handleSignOut,
        loadAuthUserData: loadData,
        closeSignUpToBecomeAMember,
        openSignUpToBecomeAMember
      }}
    >
      <SignInModal
        signInRedirect={signInRedirect}
        onClose={() => closeSignInModal()}
        setUser={setUser}
        user={user}
        signInAction={signInAction}
        setOpen={setSignInModelOpened}
        open={signInModelOpened}
      />

      <BecomeAMemberModal
        onClose={() => closeSignUpToBecomeAMember()}
        setUser={setUser}
        user={user}
        setOpen={setMembersOnlyModelOpened}
        open={membersOnlyModelOpened}
      />
      {typeof window !== "undefined" ? children : ""}
    </Provider>
  )
}

export { AuthProvider, Consumer as AuthConsumer, AuthUserContext }
export default AuthUserContext
