import React, { useState, useEffect, useContext, useRef } from "react"
import styled from "styled-components"

import { InputAdornment, TextField, IconButton } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"

import { loginUser, registerUser } from "../../../services/user.services"
import { navigate, Link } from "gatsby"
import {
  BecomeAMemberHeading,
  StyledDrawer,
  ToContinueTermsOfUseText,
  ColumnWrapper,
  DownloadOrUseAuthButtonWrapper,
  FormInputsColumn,
  DownloadAppButton,
  FormInputStyled,
  ContinueWithFacebookButton,
  ORWrapper,
  ContinueWithEmailSubmit,
} from "./styles"
import {
  USER_AGREEMENT,
  PRIVACY_POLICY,
  LOGIN,
  APP_PAGE,
} from "../../../components/Layout/routes"
import LockIcon from "@material-ui/icons/Lock"
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate"
import FacebookIcon from "@material-ui/icons/Facebook"
import { AccountCircle } from "@material-ui/icons"
import EmailIcon from "@material-ui/icons/Email"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
function BecomeAMemberModal(props) {
  let { appName } = props

  const {
    setOpen,
    open,
    signInAction,
    setUser,
    onClose,
    signInRedirect,
  } = props
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})

  const handleRegisterSubmit = () => {
    setSubmitting(true)
    let tempName = name.split(" ")
    let first_name = tempName[0]
    let last_name = tempName[1] || "lastname"

    registerUser({ email, password, first_name, last_name })
      .then(decoded => {
        setSubmitting(false)
        setUser(decoded)

        onClose()
        setName("")
        setEmail("")
        setPassword("")
        setErrors({})
        if (signInRedirect) {
          navigate(signInRedirect)
        }
      })
      .catch(err => {
        setSubmitting(false)
        setErrors(err.response.data)

        throw err
      })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleKeyDown = e => {
    let { keyCode } = e
    if (keyCode === 13) {
      handleRegisterSubmit()
    }
  }

  return (
    <StyledDrawer
      anchor="bottom"
      aria-labelledby="becomeAMemberDrawer"
      open={open}
    >
      <ColumnWrapper>
        <BecomeAMemberHeading>
          Sign up for free to continue using {appName}
        </BecomeAMemberHeading>
        <ToContinueTermsOfUseText>
          By continuing you agree to our{" "}
          <Link to={USER_AGREEMENT}> Terms of Use </Link> and{" "}
          <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
        </ToContinueTermsOfUseText>

        <DownloadOrUseAuthButtonWrapper>
          <Link to={APP_PAGE}>
            <DownloadAppButton startIcon={<SystemUpdateIcon />}>
              Continue by downloading our app
            </DownloadAppButton>
          </Link>
          {/* <ContinueWithFacebookButton startIcon={<FacebookIcon />}>
            Continue using Facebook
          </ContinueWithFacebookButton> */}
        </DownloadOrUseAuthButtonWrapper>
        <ORWrapper>
          <span />
          OR
          <span />
        </ORWrapper>
        <FormInputsColumn data-private>
          <FormInputStyled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            onChange={e => setName(e.target.value)}
            label="Name"
            helperText={
              errors.first_name ? errors.first_name : "First and last name"
            }
            error={errors.first_name ? true : false}
            fullWidth
            value={name}
            size="small"
            variant="outlined"
          />
          <FormInputStyled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            label="Email"
            fullWidth
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            size="small"
            helperText={errors.email ? errors.email : "Your email address"}
            error={errors.email ? true : false}
          />
          <FormInputStyled
            helperText={
              errors.password ? errors.password : "Make sure it's 8 letters"
            }
            error={errors.password ? true : false}
            onKeyUp={e => handleKeyDown(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    style={{ opacity: 7 }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            fullWidth
            onChange={e => setPassword(e.target.value)}
            size="small"
            type={showPassword ? "text" : "password"}
            variant="outlined"
          />
          <ContinueWithEmailSubmit
            disabled={submitting}
            onClick={() => handleRegisterSubmit()}
          >
            {submitting ? "Please wait..." : "Continue with email"}
          </ContinueWithEmailSubmit>
        </FormInputsColumn>
        <ToContinueTermsOfUseText style={{ marginTop: 20 }}>
          Already have an account? <Link to={LOGIN}> Login </Link>
        </ToContinueTermsOfUseText>
      </ColumnWrapper>
    </StyledDrawer>
  )
}

BecomeAMemberModal.defaultProps = {
  setOpen: () => {},
  open: false,
  appName: "FindAnHerb",
  signInAction: "",
  setUser: () => {},
  onClose: () => {},
  signInRedirect: "",
}

export default BecomeAMemberModal
