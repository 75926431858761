import { Button, Drawer, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { BreakpointMobile } from '../../../components/Layout/GlobalStyle'

export const StyledDrawer = styled(Drawer)`
    .MuiBackdrop-root{
        backdrop-filter: blur(5px);

    }

    .MuiDrawer-paperAnchorBottom{
        padding: 30px;
        min-height: 612px;
    }

    @media only screen  and (max-width: ${BreakpointMobile + 'px'}){
        max-width: initial;
        .MuiDrawer-paperAnchorBottom{
            padding: 30px;
            min-height: initial;
        }
    }
`

export const BecomeAMemberHeading = styled(Typography)`
    font-size: 24px;
    font-family: var(--Font1NotCondensed);
    font-weight: bold;
    color: ${(props => props.theme.primaryTextColor)};
    text-align: center;
    padding-bottom: 20px;

    @media only screen  and (max-width: ${BreakpointMobile + 'px'}){
       padding-bottom: 10px;
    }
`

export const ToContinueTermsOfUseText = styled(Typography)`
    font-size: 12px;
    font-family: var(--Font2);
    color: rgba(5, 5, 5, 0.5);
    text-align: center;
    a{
        cursor: pointer;
        text-decoration: underline;
        color: ${props => props.theme.textLinkColorOne};
    }
    padding-bottom: 20px;
`

export const ColumnWrapper = styled.div`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
    @media only screen  and (max-width: ${BreakpointMobile + 'px'}){
       min-height: initial;
    }

`


export const ORWrapper = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    width: 100%;
    max-width: 320px;

    text-transform: lowercase;
    span{
        display: block;
        width: 45%;
        height: 1px; 
        background: rgba(0, 0, 0, 0.1);
    }

`

export const DownloadOrUseAuthButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 90px;
    width: 100%;
    justify-content: space-between;
    max-width: 320px;

    
`

export const DownloadAppButton = styled(Button)`
    height: 40px;
    width: 100%;
    font-family: var(--Font1NotCondensed);
    font-weight: bold;
    text-transform: initial;
    border: 1px solid rgba(133, 140, 148, 0.5);
    color: rgba(5, 5, 5, 0.8);
    text-align: left;
    border-radius: 7px;

    svg{
        color: rgba(5, 5, 5, 0.8);       
         fill: rgba(5, 5, 5, 0.8);

        
    }
`

export const ContinueWithFacebookButton = styled(Button)`
    height: 40px;
    width: 100%;
    font-family: var(--Font1NotCondensed);
    font-weight: bold;
    text-transform: initial;
    border: 1px solid rgba(133, 140, 148, 0.5);
    color: rgba(5, 5, 5, 0.8);
    background: #4267B2;
    color: white;
    text-align: left;
    border-radius: 7px;

`

export const ContinueWithEmailSubmit = styled(Button)`
    height: 40px;
    width: 100%;
    font-family: var(--Font1NotCondensed);
    font-weight: bold;
    text-transform: initial;
    /* border: 1px solid rgba(133, 140, 148, 0.5); */
    color: rgba(5, 5, 5, 0.8);
    background: #05AA97;
    color: white;
    border-radius: 7px;
    text-align: left;
    margin: auto;
    max-width: 210px;

    :active{
        background: #02907f;
    }
    @media only screen  and (max-width: ${BreakpointMobile + 'px'}){
        max-width: initial;
    }

`
export const FormInputsColumn = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    width: 100%;
    max-width: 320px;
`



export const FormInputStyled = styled(TextField)`
    width: 100%;
    border-radius: 7px;

font-family: var(--Font1NotCondensed);
    .MuiInputBase-root{
        border-radius: 7px;
    }

    p{
        margin-left: 0px;
    }

    svg{
        color: rgba(5, 5, 5, 0.5);
        fill: rgba(5, 5, 5, 0.5);

        
    }
`