import React from "react"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import MaterialAlert from "./MaterialAlert"
import PropTypes from "prop-types"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

function MaterialSnackbar({
  open,
  setOpen,
  severity,
  autoHideDuration = 5000,
  message,
  position: { horizontal, vertical },
}) {
  const classes = useStyles()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <MaterialAlert onClose={handleClose} severity={severity}>
        <div dangerouslySetInnerHTML={{ __html: message }}>

        </div>
      </MaterialAlert>
    </Snackbar>
  )
}

MaterialSnackbar.defaultProps = {
  severity: "success",
  autoHideDuration: 5000,
  message: "this is message",
  position: {
    vertical: "bottom",
    horizontal: "right",
  },
}

MaterialSnackbar.propTypes = {
  severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  position: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
}

export default MaterialSnackbar
