import React, { createContext, useState, useEffect } from 'react';
import MaterialSnackbar from '../components/common/MaterialSnackbar';
import isEmpty from '../utils/isEmpty';


let ErrorsContext;
const { Provider, Consumer } = ErrorsContext = createContext();

const ErrorsProvider = ({ children }) => {

  
  
  


  const [errorAlertOpened, setErrorAlertOpened] = useState(false);
  const [errors, setErrors] = useState({});

  const clearErrors = () => {
    setErrors({});
  };

  useEffect(() => {
    setErrors({})
  }, [children])

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed && errors && !isEmpty(errors)) {
      setErrorAlertOpened(true);
    }

    return () => (isSubscribed = false);
  }, [errors]);


  return (
    <Provider value={{ errors, setErrors, clearErrors, setErrorAlertOpened }}>
        <MaterialSnackbar
        message={
          errorAlertOpened
            ? `<ul>${Object.keys(errors).map((item, index) => {
                return `<li>${item}: <b>${errors[item]}</b></li>`;
              })}</ul>`.replaceAll(",", "")
            : "error"
        }
        severity={"error"}
        open={errorAlertOpened}
        setOpen={setErrorAlertOpened}
      />
      {children}
    </Provider>
  )
}

export { ErrorsProvider, Consumer as ErrorsConsumer, ErrorsContext }
export default ErrorsContext;

