import React from 'react'
import styled from 'styled-components'

import { ChevronForwardIcon } from '../../GlobalStyle'


const SidebarLinkStyled = styled.button`
    height: 43px;
    padding-left: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 14px;
    justify-content: space-between;
    background: transparent;
    border: 0px solid transparent;
    cursor: pointer;
    .link-title{
        color: #4D4D4D;
        font-family: var(--Font2);
        font-size: 14px;
    }

    :hover{
        transition: 0.2s ease-in-out;

        background: rgba(0, 0, 0, 0.05);
    }

    :active{
        background: rgba(0, 0, 0, 0.3);

    }
`

function SidebarButton(props) {
    let {onClick, title} = props
    return (
        <SidebarLinkStyled onClick={() => onClick()}>
            <div className="link-title">
            {title}
            </div>
            <div className="go-to-icon">
                <ChevronForwardIcon />
            </div>
        </SidebarLinkStyled>
    )
}

SidebarButton.defaultProps = {
    onClick: () => {},
    title: "Title"
}


export default SidebarButton