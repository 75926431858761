
const inDevelopment = process.env.NODE_ENV === "development" ? true : false;

module.exports = {
  inDevelopment,
  mongoDBOptions: {
    dbName: `herbs`,
    auth: {
      user: "admin",
      password: "password1"
    },
    collection: [`products`, `herbs`, `users`, `ailments`, `symptoms`, `remedies`, `herbfamilies`, `blogarticles`],
    server: {
      address: "herbs-shard-00-01.sr51g.mongodb.net",
      port: 27017
    },
    extraParams: {
      retryWrites: true,
      ssl: true,
      authSource: "admin",
    }
  }
}   
