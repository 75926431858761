import React, { useState, useContext, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { LoginRegisterContainer } from './LoginSection'
import { DisclaimerMessage } from "../../../components/Layout/GlobalStyle";
import { PRIVACY_POLICY } from "../../../components/Layout/routes";
import { Link } from 'gatsby'
import styled from 'styled-components'



function RegisterSection(props) {
  let { handleSubmit, classes, last_name, first_name, setLastName, setFirstName, password, email, submitting, errors, setEmail, setPassword } = props;
  const handleKeyDown = (e) => {
    let { keyCode } = e;
    if (keyCode === 13) {
      handleSubmit()
    }
  }
  return (
    <LoginRegisterContainer>
      <div className="title-and-inputs">

        <div className="form-title">Create an account:</div>
        <div data-private className="input-wrapper">
          <TextField disabled={submitting} size="small" value={first_name} onChange={(e) => setFirstName(e.target.value)} helperText={errors.first_name ? errors.first_name : ""} error={errors.first_name ? true : false} id="firstNameFormInput" label="First Name" variant="outlined" />
          <TextField disabled={submitting} size="small" value={last_name} onChange={(e) => setLastName(e.target.value)} helperText={errors.last_name ? errors.last_name : ""} error={errors.last_name ? true : false} id="lastNameFormInput" label="Last Name" variant="outlined" />
          <TextField disabled={submitting} size="small" value={email} onChange={(e) => setEmail(e.target.value)} helperText={errors.email ? errors.email : ""} error={errors.email ? true : false} id="emailRegisterFormInput" label="Email" variant="outlined" />
          <TextField disabled={submitting} size="small" type="password" onKeyUp={(e) => handleKeyDown(e)} value={password} onChange={(e) => setPassword(e.target.value)} helperText={errors.password ? errors.password : "Must contain at least 8 characters"} error={errors.password ? true : false} id="passwordRegisterFormInput" label="Password" variant="outlined" />
        </div>


      </div>


      <Button className={classes.submitForm} disableElevation variant={"contained"} color="secondary" disabled={submitting} fullWidth onClick={() => handleSubmit()} >
        {submitting ? "Registering" : "Register"}
      </Button>
      <DisclaimerMessage color="rgba(0, 0, 0, 0.54)" >
        By registering your account, you agree to our <Link to={PRIVACY_POLICY}> Terms of Service</Link> and <Link to={PRIVACY_POLICY}> Privacy Policy</Link>.
      </DisclaimerMessage>


    </LoginRegisterContainer>
  )
}

RegisterSection.defaultProps = {
  handleSubmit: () => {},
  password: "",
  email: "",
  last_name: "",
  first_name: "",
  submitting: false,
  setEmail: () => {},
  setPassword: () => {},
  setFirstName: () => {},
  setLastName: () => {}
}



export default RegisterSection