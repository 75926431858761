import React, { useContext, useState } from "react"
import styled from "styled-components"
import HeaderSearch from "./HeaderSearch"
import {
  PaddingLeftRightDesktop,
  BreakpointTablet,
  PaddingLeftRightMobile,
  BreakpointMobile,
  BreakpointSmallMobile,
} from "../GlobalStyle"
import { Link } from "gatsby"
import { LANDING, ACCOUNT, LOGIN, REGISTER } from "../routes"
import MenuIcon from "react-ionicons/lib/IosMenu"
import AuthUserContext from "../../../providers/AuthProvider"
import Sidebar from "./Sidebar"

export const Curtain = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  ${props =>
    props.show
      ? `z-index: ${props.zIndex}; background: rgba(0, 0, 0, 0.8);`
      : "z-index: -1 !important; background: rgba(0, 0, 0, 0);"};
`

Curtain.defaultProps = {
  zIndex: 520,
}

export const NavLinkStyled = styled.div`
  font-size: 14px;
  font-family: var(--Font2);
  color: white;
  text-decoration: underline;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  a {
    color: inherit;
  }
`

export const NavLinkButtonTypeOne = styled.button`
  font-size: 14px;
  font-family: var(--Font2);
  color: white;
  height: 33px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
  border: 1px solid white;
  border-radius: 4px;
  margin-right: 17px;
  a {
    color: inherit;
  }

  cursor: pointer;

  :hover {
    background: white;
    color: var(--Color1);
  }

  transition: 0.2s ease-in-out;
`
export const NavLinkButtonTypeTwo = styled.button`
  font-size: 14px;
  font-family: var(--Font2);
  color: white;
  height: 33px;
  width: 130px;
  padding-left: 15px;
  padding-right: 15px;
  background: var(--Color2);
  border: 1px solid var(--Color2);
  border-radius: 4px;
  a {
    color: inherit;
  }

  cursor: pointer;

  :hover {
    color: white;
    background: var(--Color2Darker);
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }

  transition: 0.2s ease-in-out;
`

export const HeaderStyles = styled.nav`
  height: 50px;
  width: 100%;
  background: var(--Color1);
  position: fixed;
  z-index: 500;

  ${props => props.hideHeader? "display: none;": "display: initial;"}

  .login-link {
    margin-right: 17px;
  }

  .user-options {
    display: flex;
    width: 100%;
    max-width: 300px;
    padding-left: 40px;
  }
  .user-auth-options {
    display: flex;
    width: 100%;
    padding-left: 40px;

    a {
      width: 100%;
      white-space: nowrap;
      :hover {
        opacity: 0.8;
      }
    }
  }

  .menu-and-brand {
    width: 200px;
    display: flex;
    align-items: center;
    padding-right: 40px;
  }

  .brand-text {
    display: block;
    width: inherit;
  }

  .menu-button {
    border-radius: 4px;
    height: 35px;
    border: 1px solid rgba(88, 88, 88, 0.5);
    margin-right: 12px;
    min-width: 35px;
    width: 35px;
    background: transparent;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background: rgba(88, 88, 88, 0.1);
    }

    :active {
      background: rgba(88, 88, 88, 0.5);
    }
  }

  .search-wrapper {
    width: 100%;
    max-width: 689px;
  }

  .header-wrapper {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--MaxWidthPage);
    margin: auto;
    padding-left: ${PaddingLeftRightDesktop};
    padding-right: ${PaddingLeftRightDesktop};
  }

  .brand {
    display: block;
    min-width: 130px;
  }

  .brand-text {
    font-family: var(--Font1);
    color: white;
    font-size: x-large;
    line-height: 50px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  }

  #navDesktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-width: var(--MaxWidthPage);
  }
  #navMobile {
    display: none;

    .brand-text {
      font-family: var(--Font1);
      color: white;
      line-height: 50px;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    }
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    #navDesktop {
      display: none;
    }

    #navMobile {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .nav-search-wrapper {
        float: right;
      }
    }
  }

  @media only screen and (max-width: ${BreakpointSmallMobile + "px"}) {
    #navMobile {
      /* .brand-text{
                font-size: x-large;
  
            }  */
    }

    .header-wrapper {
      padding-left: ${PaddingLeftRightMobile};
      padding-right: ${PaddingLeftRightMobile};
    }
  }
  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    #navMobile {
      .brand-text {
        font-size: large;
        display: block;
      }

      .menu-and-brand {
        width: 150px;

        padding-right: 20px;
      }
    }

    .header-wrapper {
      padding-left: ${PaddingLeftRightMobile};
      padding-right: ${PaddingLeftRightMobile};
    }
  }
`

function Header(props) {
  const {hideHeader = false} = props;
  const { user } = useContext(AuthUserContext)
  const [siderbarOpened, setSidebarOpened] = useState(false)

  const handleUserOptions = user.id ? (
    <div className="user-auth-options">
      <Link to={ACCOUNT}>
        <NavLinkStyled>{user.first_name}'s Account</NavLinkStyled>
      </Link>
    </div>
  ) : (
    <div className="user-options">
      <Link className="login-link" to={LOGIN}>
        <NavLinkButtonTypeOne>Login</NavLinkButtonTypeOne>
      </Link>
      <Link to={REGISTER}>
        <NavLinkButtonTypeTwo>Sign Up! It's free</NavLinkButtonTypeTwo>
      </Link>
    </div>
  )

  return (
    <>
      <Sidebar opened={siderbarOpened} setOpen={setSidebarOpened} />
      <Curtain onClick={() => setSidebarOpened(false)} show={siderbarOpened} />
      <HeaderStyles hideHeader={hideHeader}>
        <div className="header-wrapper">
          <div id="navDesktop">
            <div className="menu-and-brand">
              <button
                onClick={() => setSidebarOpened(true)}
                className="menu-button"
              >
                <MenuIcon fontSize={25} color="white" />
              </button>

              <div className="brand">
                <Link to={LANDING}>
                  <div className="brand-text">Find An Herb</div>
                </Link>
              </div>
            </div>

            <div className="search-wrapper">
              <HeaderSearch {...props} />
            </div>
            <div>{handleUserOptions}</div>
          </div>
          <div id="navMobile">
            <div className="menu-and-brand">
              <button
                onClick={() => setSidebarOpened(true)}
                className="menu-button"
              >
                <MenuIcon color="white" />
              </button>
              <div className="brand">
                <Link to={LANDING}>
                  <div className="brand-text">Find An Herb</div>
                </Link>
              </div>
            </div>
            <div className="nav-search-wrapper">
              <HeaderSearch {...props} />
            </div>
          </div>
        </div>
      </HeaderStyles>
    </>
  )
}
export default Header